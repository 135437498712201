import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Link from "../components/HelperComponents/TransitionLink"

const ThanksPage = () => {
  const {
    prismicBasicInfo: {
      data: { main_color_two, main_color_three },
    },
  } = useStaticQuery(graphql`
    query bedankt {
      prismicBasicInfo {
        data {
          orange
          main_color_two
          main_color_three
        }
      }
    }
  `)

  return (
    <div style={{ textAlign: "center", paddingTop: 75 }}>
      <SEO title="Bedankt!" />

      <h1 className="resp-hide-mobile">Bedankt voor uw bericht</h1>
      <h3>Er wordt zo spoedig mogelijk contact met uw opgenomen.</h3>

      <Link
        to={`/`}
        linkStyle={{
          display: "inline-block",
          color: main_color_three,
          textDecoration: "none",
          backgroundColor: main_color_two,
          padding: 20,
          borderRadius: "5px 5px 50px 5px",
          margin: "75px 0 100px",
          width: "100%",
          maxWidth: 250,
          fontWeight: "bold",
        }}
      >
        Klik hier om terug te keren naar de home-pagina
      </Link>
    </div>
  )
}

export default ThanksPage
